import {FormModel} from "../models/form.model";
import axios, {AxiosResponse} from "axios";
import {Store} from "../models/store";


export function postForm(form: FormModel) {
    return axios.post(`${process.env.REACT_APP_SERVER_URL}/save-form-instant-gagnant`, form, {withCredentials: true})
}

export function generatePdf() {
    return axios.post(
        `${process.env.REACT_APP_SERVER_URL}/generate-pdf-form-instant-gagnant`,
        {},
        {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/pdf'
            },
            withCredentials: true
        }
    )
}

export function getWelcome() {
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/welcome`, {withCredentials: true})
}

export function getStatus() {
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/status`, {withCredentials: true})
}

export function getStores(query: string): Promise<AxiosResponse<Store[]>> {
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/store?q=${query}`);
}
