import React, {useEffect, useRef, useState} from "react";
import './AppForm.scss'
import {FormModel} from "../../models/form.model";
import {getStores, postForm} from "../../services/httpService";
import {alertService} from "../../services/alertService";
import {useNavigate, useSearchParams} from "react-router-dom";
import {products} from "../../models/products";
import validateArrow from "../../../assets/images/validate-arrow.svg";
import {Store} from "../../models/store";
import {handleRouteVerification} from "../../services/statusService";
import ReCAPTCHA from 'react-google-recaptcha'

const SITE_KEY = process.env.REACT_APP_SITE_KEY ?? "UNDEFINED_KEY";

function AppForm() {
    const recaptcha = useRef(null);
    const [form, setForm] = useState(new FormModel());
    const [submit, setSubmit] = useState(false);
    const [win, setWin] = useState(150);
    const [availableStores, setAvailableStores] = useState<Store[]>([]);
    const [selectedStore, setSelectedStore] = useState<Store>();

    const navigate = useNavigate()
    const [searchParams, _] = useSearchParams();

    useEffect(() => {
        handleRouteVerification(navigate)
            .then((amount: number | null) => {
                if (amount !== null) {
                    setWin(amount)
                }
            })

    }, []);

    useEffect(() => {
        if (submit) {
            postForm(form).then((res) => {
                alertService.success('Le formulaire est enregistré', {autoClose: true, keepAfterRouteChange: false});
                sessionStorage.setItem("formId", res.data.formId)
                navigate("/form-sended#FormSended", {state: {formId: res.data.formId}});
            }).catch((e) => {
                alertService.error('Une erreur est survenue : ' + e.response.data.error, {
                    autoClose: true, keepAfterRouteChange: false
                })
            });
            setSubmit(false);
        }
    }, [form, submit, navigate]);


    const formIsValid = () => {
        return (form.mail !== null
                && form.mail.trim() !== '')
            && (form.telephone !== null && form.telephone.trim() !== '')
            && (form.magasin !== null && form.magasin.trim() !== '')
            && (form.codePostal !== null)
            && (form.ville !== null
                && form.ville.trim() !== '')
            && form.reglementJeux
            && form.produit !== null
            && form.prenom !== null && form.prenom.trim() !== ''
            && form.nom !== null && form.nom.trim() !== ';'
            //@ts-ignore
            && recaptcha?.current?.getValue();
    }

    function updateStoreOption(value: string) {
        getStores(value).then((res) => {
            if (res.data.length > 0) {
                setAvailableStores(res.data);
            }
        })
    }

    const handleInputChange = (event: any) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : (event.target.value || null);
        const name = event.target.name;


        if (name === "magasin") {
            updateStoreOption(value)
        } else {
            setForm({
                ...form, [name]: value
            } as FormModel);
        }
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();

        // Form Validation
        let error: string | null = null;

        if (!formIsValid() && form.magasin === null) {
            error = 'Veuillez sélectionner un magasin dans la liste.'
        } else if (!formIsValid()) {
            error = 'Veuillez remplir tous les champs obligatoires.'
        } else if (form.codePostal !== null && !form.codePostal.toString().match(/^\d{5}$/)) {
            error = 'Le code postal doit être composé de 5 chiffres'
        } else if (form.mail != null && !form.mail.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
            error = 'Veuillez saisir une adresse mail valide'
        } else if (submit) {
            error = 'Votre demande est déjà en cours de traitement.'
        }

        // Send data to server
        if (error === null) {
            setForm({
                //@ts-ignore
                ...form, captchaValue: recaptcha.current.getValue()
            } as FormModel);
            setSubmit(true);
        } else {
            alertService.error(error, {autoClose: true, keepAfterRouteChange: false});
        }
    }

    const displayLoader = () => {
        if (submit) {
            return (<div className="loader__overlay">
                <div className="overlay__inner">
                    <div className="overlay__content"><span className="spinner"/></div>
                </div>
            </div>);
        }
    }

    function displayProductOption() {
        return products.map((product, index) => {
            return (<option key={index} value={product}>{product}</option>);
        });
    }

    function selectStore(store: Store) {
        setForm({
            ...form, magasin: store.router
        } as FormModel);
        setSelectedStore(store);
        setAvailableStores([]);
    }

    function handleRemoveSelectedStore() {
        setForm({
            ...form, magasin: null
        } as FormModel);
        setSelectedStore(undefined);
    }

    return (<form className="AppForm p-2 needs-validation" onSubmit={handleSubmit} noValidate={true}>
        <div className={"congratulation text-white text-center"}>
            <h2 className={"congratulation-title"}>Félicitations !</h2>
            <p className={"congratulation-content"}>
                Pour bénéficier de votre remise de {win}€<sup>(1)</sup> à valoir sur un futur projet, nous vous
                invitons
                à remplir le formulaire ci-dessous 🙂
            </p>
        </div>
        <div className={"notification-box"}>
            <img className={"notification-img"}
                 src="https://monsieurstore.com/wp-content/uploads/2022/03/Groupe-4060.svg"/>
            <div>
                <p className={"notification-text"}>
                    Vous disposez d'une remise de {win}€ à valoir sur un achat<sup>(1)</sup>
                </p>
            </div>
        </div>
        <div className={"row form-fields"}>
            <div className="col-12 mb-3">
                <label htmlFor="magasin" className="form-label">VOTRE MAGASIN<span
                    className={'required'}>*</span></label>
                <div className="field-background">
                    {selectedStore ? (<div className="p-2 d-flex">
                        <p className="mb-0 fw-semibold">Monsieur Store - {selectedStore.post_title}</p>
                        <button className="btn-close ms-auto"
                                onClick={() => handleRemoveSelectedStore()}

                        ></button>
                    </div>) : (<input
                        type="text"
                        className="form-control"
                        id="magasin"
                        name="magasin"
                        placeholder="Entrez votre code postal pour choisir votre magasin"
                        required={true}
                        onChange={handleInputChange}
                    />)}
                </div>

                {availableStores.length > 0 && (
                    <div className={`d-flex flex-column gap-2 p-2`}>
                        {availableStores.map(store => (
                            <div
                                key={store.place_id}
                                className={`p-2 d-flex flex-column border-red select-option`}
                                onClick={() => selectStore(store)}
                            >
                                <p className={`mb-0 fw-semibold`}>Monsieur Store - {store.post_title}</p>
                                <p className={`mb-0`}>{store.address}</p>
                            </div>
                        ))}
                    </div>
                )}

            </div>
            <div className="col-12 mb-3">
                <label htmlFor="produit" className="form-label">VOTRE FUTUR PRODUIT</label>
                <div className={"field-background"}>
                    <select id={'produit'} className={'form-select'} name={'produit'} required={false}
                            onChange={handleInputChange}>
                        <option value={''}>Sélectionnez une famille de produit</option>
                        {displayProductOption()}
                    </select>
                </div>
            </div>

            <div className="half-input">
                <label htmlFor="nom" className="form-label">VOTRE NOM<span className={'required'}>*</span></label>
                <div className={"field-background"}>
                    <input type="text" className="form-control" id="nom" name={'nom'} placeholder="Nom"
                           required={true}
                           onChange={handleInputChange}/>
                </div>
            </div>
            <div className="half-input">
                <label htmlFor="prenom" className="form-label">VOTRE PRÉNOM <span
                    className={'required'}>*</span></label>
                <div className={"field-background"}>
                    <input type="text" className="form-control" id="prenom" name={'prenom'}
                           placeholder="Prénom"
                           required={true}
                           onChange={handleInputChange}/>
                </div>
            </div>

            <div className="half-input">
                <label htmlFor="mail" className="form-label">VOTRE ADRESSE MAIL<span
                    className={'required'}>*</span></label>
                <div className={"field-background"}>
                    <input type="email" className="form-control" id="mail" name={'mail'} placeholder="Adresse mail"
                           required={true}
                           onChange={handleInputChange}/>
                </div>
            </div>
            <div className="half-input">
                <label htmlFor="telephone" className="form-label">VOTRE TÉLÉPHONE<span
                    className={'required'}>*</span></label>
                <div className={"field-background"}>
                    <input type="text" className="form-control" id="telephone" name={'telephone'}
                           placeholder="Téléphone"
                           required={true}
                           onChange={handleInputChange}/>
                </div>
            </div>
            <div className="half-input">
                <label htmlFor="codePostal" className="form-label">VOTRE CODE POSTAL<span
                    className={'required'}>*</span></label>
                <div className={"field-background"}>
                    <input type="tel" className="form-control" id="codePostal" name={'codePostal'}
                           placeholder="Code postal"
                           required={true}
                           onChange={handleInputChange}/>
                </div>
            </div>
            <div className="half-input">
                <label htmlFor="ville" className="form-label">VILLE<span className={'required'}>*</span></label>
                <div className={"field-background"}>
                    <input type="text" className="form-control" id="ville" name={'ville'} placeholder="Ville"
                           required={true}
                           onChange={handleInputChange}/>
                </div>
            </div>

            <div className="col-12 d-flex form-checkbox">
                    <input className="form-check-input" type="checkbox" value="" id="reglementJeux"
                           name={'reglementJeux'}
                           required={true} onChange={handleInputChange}/>

                    <label className="form-check-label my-auto" htmlFor="reglementJeux">
                        J'accepte le réglement du jeu concours <span className="required">*</span>
                    </label>

            </div>
            <div className="col-12 d-flex form-checkbox">
                    <input className="form-check-input" type="checkbox" value="" id="conseilOffre"
                           name={'conseilOffre'}
                           onChange={handleInputChange}/>
                <div className="col d-flex">
                    <label className="form-check-label my-auto" htmlFor="conseilOffre">
                        Je souhaite profiter de tous les conseils et offres de Monsieur Store
                    </label>
                </div>
            </div>

            <div className="col-12 d-flex little-margin-top">
                <ReCAPTCHA ref={recaptcha} sitekey={SITE_KEY} />
            </div>
            <div className="d-flex flex-row">
                <div className="col-12 d-flex flex-column form-btn">
                    <button type={'submit'}
                            className="form-btn-valider btn btn-submit m-auto mb-5 text-white text-uppercase py-3 px-4">
                        Valider
                        <img src={validateArrow}/>
                    </button>
                </div>
            </div>
        </div>
        {displayLoader()}
    </form>)
}

export default AppForm;
