export class FormModel {
    nom: string |null;
    prenom: string |null;
    mail: string | null;
    telephone: string | null;
    magasin: string | null;
    produit: string | null;
    codePostal: number | null;
    ville: string | null
    reglementJeux: boolean;
    conseilOffre: boolean;
    captchaValue: string | null;

    constructor() {
        this.nom = null;
        this.prenom = null;
        this.mail = null;
        this.telephone = null;
        this.magasin = null;
        this.produit = null;
        this.codePostal = null;
        this.ville = null;
        this.reglementJeux = false;
        this.conseilOffre = false;
        this.captchaValue = null;
    }
}
