import React, {useEffect, useState} from 'react';
import './Alert.scss'
import {alertService, alertType} from "../../services/alertService";
import {Subscription} from "rxjs";

type MyProp = {
    id: string,
    fade: boolean
}

function Alert(props: MyProp) {
    let subscription: Subscription;
    const [alerts, setAlerts] = useState([] as any[]);

    useEffect(() => {
        subscription = alertService
            .onAlert(props.id)
            .subscribe(alert => {
                // clear alerts when an empty alert is received
                if (!alert.message) {
                    // filter out alerts without 'keepAfterRouteChange' flag
                    const alertsTmp = alerts.filter((x: any) => x.keepAfterRouteChange);

                    // remove 'keepAfterRouteChange' flag on the rest
                    alerts.forEach((x: any) => delete x.keepAfterRouteChange);

                    setAlerts(alertsTmp);
                    return;
                }

                // add alert to array
                setAlerts([...alerts, alert]);

                setTimeout(() => removeAlert(alert), 3000);
            });

        return () => {
            // unsubscribe & unlisten to avoid memory leaks
            subscription.unsubscribe();
        }
    }, []);

    const removeAlert = (alert: any) => {
        if (props.fade) {
            // fade out alert
            const alertWithFade = { ...alert, fade: true };
            setAlerts(alerts.map((x: any) => x === alert ? alertWithFade : x));

            // remove alert after faded out
            setTimeout(() => {
                setAlerts(alerts.filter((x: any) => x !== alertWithFade))
            }, 250);
        } else {
            // remove alert
            setAlerts(alerts.filter((x: any) => x !== alert))
        }
    }

    const cssClasses = (alert: any) => {
        if (!alert) return;

        const classes = ['alert', 'alert-dismissable', 'text-center'];

        const alertTypeClass = {
            [alertType.success]: 'alert alert-success',
            [alertType.error]: 'alert alert-danger',
            [alertType.info]: 'alert alert-info',
            [alertType.warning]: 'alert alert-warning'
        }

        classes.push(alertTypeClass[alert.type]);

        if (alert.fade) {
            classes.push('fade');
        }

        return classes.join(' ');
    }

    const cssDisplay = () => {
        const classes = ['m-3', 'Alert']
        if (!alerts.length)
            classes.push('d-none');

        return classes.join(' ')
    }

    return (
        <div className={cssDisplay()}>
                {alerts.map((alert: any, index: number) =>
                    <div key={index} className={cssClasses(alert)}>
                        <span dangerouslySetInnerHTML={{__html: alert.message}}/>
                    </div>
                )}
        </div>
    )
}

export { Alert };
