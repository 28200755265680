export const products: string[] = [
    'Store banne',
    'Store d\'extérieur',
    'Store d\'intérieur',
    'Pergolas',
    'Parasols, voiles d\'ombrage et velum',
    'Portails',
    'Volets roulants',
    'Volets battants',
    'Fenêtres',
    'Portes d\'entrée',
    'Portes de garage',
    'Portes d\'intérieur',
    'Moustiquaires',
    'Maison connectée',
    'Réparation et remplacement',
    'Marquises',
    'Garde-corps extérieurs',
    'Carports'
]
