import {AxiosResponse} from "axios";
import {NavigateFunction} from "react-router-dom";
import {getStatus} from "./httpService";
export {}

export function verifyCorrectRoute(res: AxiosResponse<any>, navigate: NavigateFunction) {
    if (res.status === 204) {
        navigate("/")
    } else if (res.status === 200 && res.data.amount && !res.data.filledForm) {
        navigate("/form")
    } else if (res.status === 200){
        navigate("/form-sended")
    }
}

export function handleRouteVerification(navigate: NavigateFunction): Promise<number>  {
    return getStatus().then((res) => {
        verifyCorrectRoute(res, navigate);
        return res.data.amount
    }).catch(() => {
        navigate("/form-already-played")
        return null

    })
}
