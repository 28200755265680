import React from 'react';
import './App.scss';
import {Alert} from "./components/Alert/Alert";
import logo from "../assets/images/logo.png";
import AppForm from "./components/AppForm/AppForm";
import {Route, Routes, useLocation} from "react-router-dom";
import FormSended from "./components/FormSended/FormSended";
import FormAlreadyPlayed from "./components/FormAlreadyPlayed/FormAlreadyPlayed";
import AppLP from "./components/AppLP/AppLP";
import AppLayout from "./components/AppLayout/AppLayout";

function App() {
    const location = useLocation();

    return (
        <div className="App">
            <Alert id="default-alert" fade={false}/>
            <nav>
                <a href="https://monsieurstore.com/" className="text-decoration-none text-white h-100">
                    <img src={logo} alt="logo mr store" className="logo max-h-100 w-auto"/>
                </a>
            </nav>

            <Routes>
                <Route path="/" element={<AppLP />} />
                <Route path="/form" element={<AppLayout><AppForm /></AppLayout>} />
                <Route path="/form-sended" element={<AppLayout><FormSended /></AppLayout>} />
                <Route index path="/form-already-played" element={<AppLayout><FormAlreadyPlayed /></AppLayout>} />
            </Routes>
        </div>
    );
}


export default App;
