import React, {useEffect} from "react";
import './FormSended.scss'
import {generatePdf, getStatus} from "../../services/httpService";
import {useLocation, useNavigate} from "react-router-dom";
import logoFB from "../../../assets/images/FB_NOIR.webp";
import logoInsta from "../../../assets/images/INSTA_NOIR.webp";
import {alertService} from "../../services/alertService";
import {handleRouteVerification, verifyCorrectRoute} from "../../services/statusService";

function FormSended() {
    const location = useLocation();
    const navigate = useNavigate()

    useEffect(() => {
        handleRouteVerification(navigate);
    }, []);

    const downloadPdf = () => {
        generatePdf()
            .then((res) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([res.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `remise-monsieurstore-instant-gagnant.pdf`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode?.removeChild(link);
            })
    }

    return (
        <div className="row" id={"FormSended"}>
            <div className="main-remise d-flex flex-column">
                <div className={"title-remise"}>
                    <h2 className={"title-remise-text"}>
                        Merci
                    </h2>
                </div>
                <div className={"content-remise"}>
                    <p className={"text-white text-center"}>
                        Demande validée.
                    </p>
                    <p className={"text-white text-center content-remise"}>
                        Votre magasin Monsieur Store vous recontactera dans les plus brefs délais !
                    </p>
                </div>
                <div className={"div-btn-remise"}>
                    <button className={"btn btn-remise text-center"} onClick={downloadPdf}>
                        <span className={"btn-remise-text"}>JE TELECHARGE MA REMISE</span>
                    </button>
                </div>
                <div className="contest-div">
                    <div className="flex-row">
                        <a href={'https://www.facebook.com/monsieurstore.france'}>
                            <img src={logoFB} alt="logo facebook" className="logo" />
                        </a>
                        <a href={'https://www.instagram.com/monsieurstore.france'}>
                            <img src={logoInsta} alt="logo instagram" className="logo" />
                        </a>
                    </div>
                    <p className={"btn-back-site-text"}>Ne manquez pas notre animation sur Facebook et Instagram, rejoignez-nous dès maintenant pour tenter de gagner un jeu TTMC ou un pull de Noël !</p>
                </div>
                <div className={"div-btn-retour"}>
                    <a className={"btn btn-retour text-center"} href={'https://monsieurstore.com/'}>
                        <span className={"btn-back-site-text"}>Retourner sur le site Monsieur Store ➔</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default FormSended;
