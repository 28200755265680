import React, {useEffect, useRef, useState} from "react";
import './AppLP.scss'
import {getWelcome} from "../../services/httpService";
import {useNavigate} from "react-router-dom";
import {handleRouteVerification} from "../../services/statusService";
import groupe from "../../../assets/images/encart_mai.webp";

function AppLP() {

    const [opened, setOpen] = useState(false);
    const [win, setWin] = useState(0);
    const effectRan = useRef(false);

    const navigate = useNavigate()

    useEffect(() => {
        if (effectRan.current) return;
        getWelcome().then((res) => {
            setWin(parseInt(res.data.amount));
        }).catch((e) => {
            handleRouteVerification(navigate);
        });

        effectRan.current = true;
    }, []);


    const handleClick =  (event: React.MouseEvent<HTMLDivElement>) => {
        if (opened){
            navigateToForm()
        }
        setOpen(true);
    }

    const navigateToForm = () => {
        navigate("/form");
    }

    const showFrigo = () => {
        if (!opened){
            return ""
        }
        switch (win) {
            case 150:
                return "open150"
            case 1500:
                return "open1500"
            default:
                return ""
        }
    }

    return (<div  className={`AppLP ${showFrigo()}`}>
        <div className={'main'}>
            <div className="side-1">
                <img src={groupe} alt="group" className="encart"/>
                <div className="w-fit h-fit mentions-legales on-bottom-screen default">
                    <a
                        href="https://monsieurstore.com/mentions-legales-et-reglement-animation-novembre-2024/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-black text-decoration-none w-fit"
                    >
                        (1) Règlement et Mentions légales
                    </a>
                </div>
            </div>
            <div className="side-2 " onClick={handleClick}>
                {

                }
            </div>
            <div className="w-fit h-fit mentions-legales mobile">
                <a
                    href="https://monsieurstore.com/mentions-legales-et-reglement-animation-novembre-2024/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-black text-decoration-none w-fit"
                >
                    (1) Règlement et Mentions légales
                </a>
            </div>


        </div>

    </div>)
}

export default AppLP;
