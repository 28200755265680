import React, { ReactNode } from "react";
import './AppLayout.scss';

interface AppLayoutProps {
    children: ReactNode;
}

function AppLayout({ children }: AppLayoutProps) {
    return (
        <div className="main-container">
            <div className="side-1 relative">
                <div className="col-12 mentions-legales on-bottom-screen default">
                    <a
                        href="https://monsieurstore.com/mentions-legales-et-reglement-animation-novembre-2024/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-black text-decoration-none p"
                    >
                        (1) Règlement et Mentions légales
                    </a>
                </div>
            </div>
            <div className="side-2 pb-3">
                {children}
            </div>
            <div className="w-fit h-fit mentions-legales mobile pb-3">
                <a
                    href="https://monsieurstore.com/mentions-legales-et-reglement-animation-novembre-2024/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-black text-decoration-none w-fit"
                >
                    (1) Règlement et Mentions légales
                </a>
            </div>
        </div>
    );
}

export default AppLayout;
